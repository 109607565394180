<script setup lang="ts">
import { RouterView } from 'vue-router';
import { useStyle } from '@/styleProvider';

useStyle('root');
</script>

<template>
  <RouterView />
</template>
