import { STYLE } from '@/const';
import { inject } from 'vue';

import type { IStyleProvider, IStylesCollection } from '@/types';

export async function useStyle(cssName: string) {
  const styleProvider = inject(STYLE);
  const styleCallback = styleProvider?.getStyle(cssName);
  if (typeof styleCallback === 'function') {
    await styleCallback();
  }
}

export class StyleProvider implements IStyleProvider {
  private styles: IStylesCollection = {};

  appendStyles(styles: IStylesCollection) {
    this.styles = {
      ...this.styles,
      ...styles,
    };
  }

  getAllStyles() {
    return this.styles;
  }

  getStyle(component: string) {
    if (!(component in this.styles)) {
      throw Error(`Style for "${component}" doesn't exist`);
    }

    return this.styles[component];
  }
}
